@import "minima";

body {
  background:lightgray url('/assets/common/background.png'); /* no-repeat scroll left center;*/
  background-size: cover;
  
  
}

a {
  color: black;
}
